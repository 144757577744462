export const wiringTypeDirDict = {
  expences: 'Расход',
  income: 'Доход',
  internalRedistribution: 'Перераспределение внутреннее',
  securityDeposit: 'Обеспечение',
  externalRedistribution: 'Перераспределение внешнее',
  reserve: 'Резерв',
};
export const fundsDict = {
  expences: [
    {
      id: 'ex1',
      code: 'ex1',
      fund: 'ФОТ сотрудников',
      title: 'ФОТ сотрудников',
      project: false,
    },
    {
      id: 'ex2',
      code: 'ex2',
      fund: 'ФОТ партнеров',
      title: 'ФОТ партнеров',
      project: false,
    },
    { id: 'ex3', fund: 'Накладные', project: false },
    { id: 'ex4', fund: 'Развитие бизнеса', project: false },
    { id: 'ex5', fund: 'Социальные программы', project: false },
    { id: 'ex6', fund: 'Непредвиденные расходы', project: false },
    {
      id: 'ex7',
      fund: 'Исполнение обязательств предыдущих лет',
      project: false,
    },
    { id: 'ex8', fund: 'Распределение прибыли', project: false },
    { id: 'ex9', fund: 'Проектные', project: true },
    { id: 'ex10', fund: '% расходы (по займам)', project: false },
    { id: 'ex11', fund: 'НДС', project: true },
    { id: 'ex12', fund: 'ЕСН', project: true },
    { id: 'ex13', fund: 'НДФЛ', project: false },
    { id: 'ex14', fund: 'Налог на прибыль', project: false },
    { id: 'ex15', fund: 'Единый налог УСН', project: false },
    { id: 'ex16', fund: 'Налог на имущество', project: false },
    { id: 'ex17', fund: 'Комиссия банка', project: true },
    { id: 'ex18', fund: 'Пени и штрафы (прочие)', project: true },
  ],
  income: [
    { id: 'in1', fund: 'Проектный доход', project: true },
    { id: 'in2', fund: '% доходы', project: false },
    { id: 'in3', fund: 'Доход от рекламы', project: false },
    { id: 'in4', fund: 'Прочий доход', project: false },
    { id: 'in5', fund: 'Экономия на НДС', project: false },
  ],
  internalRedistribution: [
    {
      id: 'ir1',
      fund: 'Поступление з/п на карту',
      project: false,
    },
    {
      id: 'ir2',
      fund: 'Поступление з/п по чеку',
      project: false,
    },
    {
      id: 'ir3',
      fund: 'На счет физ. Лица (вклад)',
      project: false,
    },
    {
      id: 'ir4',
      fund: 'Со счета физ. Лица (вклад)',
      project: false,
    },
    {
      id: 'ir5',
      fund: 'На счет организации (перечисление внутреннего займа)',
      project: false,
    },
    {
      id: 'ir6',
      fund: 'Получение внутреннего займа',
      project: false,
    },
    {
      id: 'ir7',
      fund: 'Со счета организации (возврат внутреннего займа)',
      project: false,
    },
    {
      id: 'ir8',
      fund: 'Получение возврата внутреннего займа',
      project: false,
    },
    {
      id: 'ir9',
      fund: 'Снятие б/нала в нал',
      project: false,
    },
    {
      id: 'ir10',
      fund: 'Приход из б/нала в нал',
      project: false,
    },
    {
      id: 'ir11',
      fund: 'Со счета организации на вклад организации',
      project: false,
    },
    {
      id: 'ir12',
      fund: 'На счет организации со вклада организации',
      project: false,
    },
    { id: 'ir13', fund: 'На счет ИП', project: false },
    { id: 'ir14', fund: 'Со счета ИП', project: false },
  ],
  securityDeposit: [
    { id: 'sd1', fund: 'Обеспечение заявок', project: false },
    { id: 'sd2', fund: 'Возврат обеспечения заявок', project: false },
    {
      id: 'sd3',
      fund: 'Обеспечение контракта (депозит, вексель)',
      project: true,
    },
    // { id: 'sd4', fund: 'Прочий доход', project: false },
    {
      id: 'sd4',
      fund: 'Возврат обеспечения контракта (депозит, вексель)',
      project: true,
    },
  ],
  externalRedistribution: [
    {
      id: 'er1',
      fund: 'Перечисление займов внешним организациям',
      project: false,
    },
    {
      id: 'er2',
      fund: 'Возврат займов от внешних организаций',
      project: false,
    },
    {
      id: 'er3',
      fund: 'Получение займов от внешних организаций',
      project: false,
    },
    { id: 'er4', fund: 'Возврат займов внешним организациям', project: false },
  ],
  reserve: [
    { id: 're1', fund: 'Общий резерв', project: false },
    // { id: 're3', fund: 'Безналичный резерв', project: true },
    // { id: 're4', fund: 'Наличный резерв', project: true },
  ],
  nonfund: [{ id: 'nf1', fund: 'Нефондовые', project: false }],
};
export const articlesDict = {
  ex1: [
    {
      id: 'ex11',
      fundName: 'ФОТ сотрудников',
      article: 'з/п экспертов',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex11_1',
      fundName: 'ФОТ сотрудников',
      article: 'з/п бэк-офиса',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex12',
      fundName: 'ФОТ сотрудников',
      article: '13-я з/п экспертов',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex12_1',
      fundName: 'ФОТ сотрудников',
      article: '13-я з/п бэк-офиса',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex13',
      fundName: 'ФОТ сотрудников',
      article: 'Текущие премии (непроектные) экспертов',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex13_1',
      fundName: 'ФОТ сотрудников',
      article: 'Текущие премии (непроектные) бэк-офиса',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex14',
      fundName: 'ФОТ сотрудников',
      article: 'Декретные',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex14_1',
      fundName: 'ФОТ сотрудников',
      article: 'Прочие выплаты экспертам',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex14_2',
      fundName: 'ФОТ сотрудников',
      article: 'Прочие выплаты бэк-офису',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex2: [
    {
      id: 'ex21',
      fundName: 'ФОТ партнеров',
      article: 'з/п партнеров',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex22',
      fundName: 'ФОТ партнеров',
      article: 'Прочие ФОТ партнеров',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex3: [
    {
      id: 'ex31',
      fundName: 'Накладные',
      article: 'Аренда офиса',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex31_1',
      fundName: 'Накладные',
      article: 'Электричество',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex31_2',
      fundName: 'Накладные',
      article: 'Телефония (МГТС, Ростелеком, МАНГО)',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex31_3',
      fundName: 'Накладные',
      article: 'Интернет',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex31_4',
      fundName: 'Накладные',
      article: 'Почта, курьер',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32',
      fundName: 'Накладные',
      article: 'Покупка оборудования',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_1',
      fundName: 'Накладные',
      article: 'Покупка ПО',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_2',
      fundName: 'Накладные',
      article: 'Ремонт оборудования',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_3',
      fundName: 'Накладные',
      article: 'Покупка, ремонт мебели',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_4',
      fundName: 'Накладные',
      article: 'Покупка, ремонт бытовой техники',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_4',
      fundName: 'Накладные',
      article: 'Товары для офиса, канцелярия',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_5',
      fundName: 'Накладные',
      article: 'Картриджи',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_6',
      fundName: 'Накладные',
      article: 'ЭЦП',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_7',
      fundName: 'Накладные',
      article: 'Комиссия банка (непроектная)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_8',
      fundName: 'Накладные',
      article: 'Уборка офиса',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_9',
      fundName: 'Накладные',
      article: 'Выписки, нотариус',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_10',
      fundName: 'Накладные',
      article: 'Хостинг (Nisse.ru, СУП)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_11',
      fundName: 'Накладные',
      article: 'Взносы в организации (АНЦЭА)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_12',
      fundName: 'Накладные',
      article: 'Транспорт',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_13',
      fundName: 'Накладные',
      article: 'Полиграфия',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_14',
      fundName: 'Накладные',
      article: 'Переезд',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_15',
      fundName: 'Накладные',
      article: 'Подарки (сторонним)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_16',
      fundName: 'Накладные',
      article: 'Хозяйственные расходы',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_17',
      fundName: 'Накладные',
      article: 'Командировочные (непроектные)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_18',
      fundName: 'Накладные',
      article: 'Офис менеджер',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_19',
      fundName: 'Накладные',
      article: 'Прочие накладные',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex32_20',
      fundName: 'Накладные',
      article: 'Поддержка IT-инфраструктуры',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex33',
      fundName: 'Накладные',
      article: 'Ремонт офиса (компенсация ремонта)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex4: [
    {
      id: 'ex41',
      fundName: 'Развитие бизнеса',
      article: 'Развитие и поддержка СУП',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex42',
      fundName: 'Развитие бизнеса',
      article: 'Мониторинги (внешние эксперты)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex43',
      fundName: 'Развитие бизнеса',
      article: 'СМК',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex44',
      fundName: 'Развитие бизнеса',
      article: 'Развитие и поддержка сайта',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex45',
      fundName: 'Развитие бизнеса',
      article: 'Юридическое сопровождение',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex46',
      fundName: 'Развитие бизнеса',
      article: 'Прочее. Развитие бизнеса',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex47',
      fundName: 'Развитие бизнеса',
      article: 'Обсучение IT-специалистов',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex5: [
    {
      id: 'ex51',
      fundName: 'Социальные программы',
      article: '23.02, 8.03, подарки к ДР',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex52',
      fundName: 'Социальные программы',
      article: 'Юбилеи, рождения детей',
      cash: true,
      cashless: false,
      action: 'minus',
    },
    {
      id: 'ex53',
      fundName: 'Социальные программы',
      article: 'Корпоративы',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex54',
      fundName: 'Социальные программы',
      article: 'Материальная помощь',
      cash: true,
      cashless: false,
      action: 'minus',
    },
    {
      id: 'ex53',
      fundName: 'Социальные программы',
      article: 'Прочие. Социальные программы',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex6: [
    {
      id: 'ex61',
      fundName: 'Непредвиденные расходы',
      article: 'Непредвиденные расходы',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex7: [
    {
      id: 'ex71',
      fundName: 'Исполнение обязательств предыдущих лет',
      article: 'Долги',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex72',
      fundName: 'Исполнение обязательств предыдущих лет',
      article: 'Прочие. Исполнение обязательств предыдущих лет',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex8: [
    {
      id: 'ex81',
      fundName: 'Распределение прибыли',
      article: 'Бонусы бэк-офису',
      cash: true,
      cashless: false,
      action: 'minus',
    },
    {
      id: 'ex82',
      fundName: 'Распределение прибыли',
      article: 'Бонусы сотрудникам',
      cash: true,
      cashless: false,
      action: 'minus',
    },
    {
      id: 'ex83',
      fundName: 'Распределение прибыли',
      article: 'Бонусы партнеров',
      cash: true,
      cashless: false,
      action: 'minus',
    },
    {
      id: 'ex84',
      fundName: 'Распределение прибыли',
      article: 'Прочие. Распределение прибыли',
      cash: true,
      cashless: false,
      action: 'minus',
    },
  ],
  ex9: [
    {
      id: 'ex91',
      fundName: 'Проектные',
      article: 'Сторонние соисполнители',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex92',
      fundName: 'Проектные',
      article: 'Внутренние соисполнители',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex92_1',
      fundName: 'Проектные',
      article: 'Премии внутренним исполнителям',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex92_2',
      fundName: 'Проектные',
      article: 'Внешние Эксперты',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex92_3',
      fundName: 'Проектные',
      article: 'Бонусы',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex93',
      fundName: 'Проектные',
      article: 'Прочие проектные',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex94',
      fundName: 'Проектные',
      article: 'Стоимость гарантии по контракту',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex95',
      fundName: 'Проектные',
      article: 'Стоимость депозитного обеспечения',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex96',
      fundName: 'Проектные',
      article: 'Резерв (б/нал)',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex97',
      fundName: 'Проектные',
      article: 'Резерв (нал)',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    {
      id: 'ex98',
      fundName: 'Проектные',
      article: 'Пени (по проекту)',
      cash: false,
      cashless: true,
      action: 'minus',
    },
    // {
    //   id: 'ex95',
    //   fundName: 'Проектные',
    //   article: 'НДС',
    //   cash: false,
    //   cashless: true,
    //   action: 'minus',
    // },
  ],
  ex10: [
    {
      id: 'ex101',
      fundName: '% расходы (по займам)',
      article: '% расходы (по займам)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ex11: [
    {
      id: 'ex111',
      fundName: 'НДС',
      article: 'НДС',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex12: [
    {
      id: 'ex121',
      fundName: 'ЕСН',
      article: 'ЕСН',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex13: [
    {
      id: 'ex131',
      fundName: 'НДФЛ',
      article: 'НДФЛ',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex14: [
    {
      id: 'ex141',
      fundName: 'Налог на прибыль',
      article: 'Налог на прибыль',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex15: [
    {
      id: 'ex151',
      fundName: 'Единый налог УСН',
      article: 'Единый налог УСН',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex16: [
    {
      id: 'ex161',
      fundName: 'Налог на имущество',
      article: 'Налог на имущество',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex17: [
    {
      id: 'ex171',
      fundName: 'Комиссия банка',
      article: 'Комиссия банка',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ex18: [
    {
      id: 'ex191',
      fundName: 'Пени и штрафы (прочие)',
      article: 'Пени и штрафы (прочие)',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  // ex19: [
  //   {
  //     id: 'ex201',
  //     fundName: 'Схема бонусов',
  //     article: '-',
  //     cash: true,
  //     cashless: false,
  //     action: 'minus',
  //   },
  // ],
  in1: [
    {
      id: 'in11',
      fundName: 'Проектный доход',
      article: 'Проектный доход',
      cash: false,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in12',
      fundName: 'Проектный доход',
      article: 'Проектный доход ФЛ',
      cash: true,
      cashless: false,
      action: 'plus',
    },
    {
      id: 'in13',
      fundName: 'Проектный доход',
      article: 'Проектный доход от внутренних соисполнителей ЮЛ',
      cash: false,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in14',
      fundName: 'Проектный доход',
      article: 'Проектный доход от внутренних соисполнителей ИП',
      cash: true,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in15',
      fundName: 'Проектный доход',
      article: 'Проектный доход от внутренних соисполнителей ФЛ',
      cash: true,
      cashless: false,
      action: 'plus',
    },
  ],
  in2: [
    {
      id: 'in21',
      fundName: '% доходы',
      article: '% доход по вкладам физ. лиц',
      cash: true,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in22',
      fundName: '% доходы',
      article: '% доход по вкладам организаций',
      cash: false,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in23',
      fundName: '% доходы',
      article: '% доход по займам организациям',
      cash: false,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in24',
      fundName: '% доходы',
      article: '% доход по займам физ. лиц',
      cash: true,
      cashless: false,
      action: 'plus',
    },
    {
      id: 'in25',
      fundName: '% доходы',
      article: '% по векселям и депозитам - обеспечениям по контрактам',
      cash: false,
      cashless: true,
      action: 'plus',
    },
    {
      id: 'in26',
      fundName: '% доходы',
      article:
        '%, недополученный по депозиту и векселю, - обеспечениям по контракту',
      cash: true,
      cashless: false,
      action: 'plus',
    },
    {
      id: 'in27',
      fundName: '% доходы',
      article: '% доход по депозиту',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  in3: [
    {
      id: 'in31',
      fundName: 'Доход от рекламы',
      article: 'Доход от рекламы',
      cash: true,
      cashless: true,
      action: 'plus',
    },
  ],
  in4: [
    {
      id: 'in41',
      fundName: 'Прочий доход',
      article: 'Прочий доход',
      cash: true,
      cashless: true,
      action: 'plus',
    },
  ],
  in5: [
    {
      id: 'in51',
      fundName: 'Экономия на НДС',
      article: 'Экономия на НДС',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  ir1: [
    {
      id: 'ir11',
      fundName: 'Поступление з/п на карту',
      article: 'Партнеры. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir12',
      fundName: 'Поступление з/п на карту',
      article: 'Эксперты. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir13',
      fundName: 'Поступление з/п на карту',
      article: 'Бэк-офис. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir14',
      fundName: 'Поступление з/п на карту',
      article: 'М.Л. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir15',
      fundName: 'Поступление з/п на карту',
      article: 'А.Б. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir16',
      fundName: 'Поступление з/п на карту',
      article: 'Т.Б. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir17',
      fundName: 'Поступление з/п на карту',
      article: 'Л. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
    {
      id: 'ir18',
      fundName: 'Поступление з/п на карту',
      article: 'Прочие. Поступление з/п на карту',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir2: [
    {
      id: 'ir21',
      fundName: 'Поступление з/п по чеку',
      article: 'Поступление з/п по чеку',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir3: [
    {
      id: 'ir31',
      fundName: 'На счет физ. Лица (вклад)',
      article: 'На счет физ. Лица (вклад)',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir4: [
    {
      id: 'ir41',
      fundName: 'Со счета физ. Лица (вклад)',
      article: 'Со счета физ. Лица (вклад)',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir5: [
    {
      id: 'ir51',
      fundName: 'На счет организации (перечисление внутреннего займа)',
      article: 'На счет организации (перечисление внутреннего займа)',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir6: [
    {
      id: 'ir61',
      fundName: 'Получение внутреннего займа',
      article: 'Получение внутреннего займа',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir7: [
    {
      id: 'ir71',
      fundName: 'Со счета организации (возврат внутреннего займа)',
      article: 'Со счета организации (возврат внутреннего займа)',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir8: [
    {
      id: 'ir81',
      fundName: 'Получение возврата внутреннего займа',
      article: 'Получение возврата внутреннего займа',
      cash: true,
      cashless: true,
      action: '-',
    },
  ],
  ir9: [
    {
      id: 'ir91',
      fundName: 'Снятие б/нала в нал',
      article: 'Снятие б/нала в нал',
      cash: false,
      cashless: true,
      action: '-',
    },
  ],
  ir10: [
    {
      id: 'ir102',
      fundName: 'Приход из б/нала в нал',
      article: 'Приход из б/нала в нал',
      cash: false,
      cashless: true,
      action: '-',
    },
  ],

  ir11: [
    {
      id: 'ir111',
      fundName: 'Со счета организации на вклад организации',
      article: 'Со счета организации на вклад организации',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  ir12: [
    {
      id: 'ir121',
      fundName: 'На счет организации со вклада организации',
      article: 'На счет организации со вклада организации',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  ir13: [
    {
      id: 'ir131',
      fundName: 'На счет ИП',
      article: '-',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
  ir14: [
    {
      id: 'ir141',
      fundName: 'Со счета ИП',
      article: '-',
      cash: true,
      cashless: true,
      action: 'plus',
    },
  ],
  sd1: [
    {
      id: 'sd11',
      fundName: 'Обеспечение заявок',
      article: 'Обеспечение заявок',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  sd2: [
    {
      id: 'sd21',
      fundName: 'Возврат обеспечения заявок',
      article: 'Возврат обеспечения заявок',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  sd3: [
    {
      id: 'sd31',
      fundName: 'Обеспечение контракта (депозит, вексель)',
      article: 'Обеспечение контракта (депозит, вексель)',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  sd4: [
    {
      id: 'sd41',
      fundName: 'Возврат обеспечения контракта (депозит, вексель)',
      article: 'Возврат обеспечения контракта (депозит, вексель)',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  er1: [
    {
      id: 'er11',
      fundName: 'Перечисление займов внешним организациям',
      article: 'Перечисление займов внешним организациям',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  er2: [
    {
      id: 'er21',
      fundName: 'Возврат займов от внешних организаций',
      article: 'Возврат займов от внешних организаций',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  er3: [
    {
      id: 'er31',
      fundName: 'Получение займов от внешних организаций',
      article: 'Получение займов от внешних организаций',
      cash: false,
      cashless: true,
      action: 'plus',
    },
  ],
  er4: [
    {
      id: 'er41',
      fundName: 'Возврат займов внешним организациям',
      article: 'Возврат займов внешним организациям',
      cash: false,
      cashless: true,
      action: 'minus',
    },
  ],
  re1: [
    {
      id: 're11',
      fundName: 'Общий резерв',
      article: 'Направление в резерв',
      cash: true,
      cashless: true,
      action: 'minus',
    },
    {
      id: 're21',
      fundName: 'Общий резерв',
      article: 'Выход из резерва',
      cash: true,
      cashless: true,
      action: 'plus',
    },
  ],
  nf1: [
    {
      id: 'nf11',
      fundName: 'Нефондовые',
      article: 'Нефондовые',
      cash: true,
      cashless: true,
      action: 'minus',
    },
  ],
};
// MOCK DATA:
export const projectFinance = () => {
  return {
    contractSum: {
      //Сумма контракта
      plan: 0,
      fact: 0,
    },
    nds: {
      // 1.1. НДС согласно контракту за вычетом НДС уплачиваемого соисполнителем
      plan: 0,
      fact: 0,
    },
    cashlessPayment: {
      // 1.2. Безналичная оплата соисполнителей
      plan: 0,
      fact: 0,
    },
    irretrievablуGuarantee: {
      // 1.3. Безвозвратное обеспечение (премии страховым компаниям, комиссии банков за банковсике гарантии и т.п.)
      plan: 0,
      fact: 0,
    },
    cashlessReserve: {
      // 1.4. Безналичный резерв
      plan: 0,
      fact: 0,
    },
    viContractSum: {
      // Vi Сумма Контракта без НДС, за вычетом безвозвратного обеспечения и сумм, выплаченных организациям-соисполнителям
      plan: 0,
      fact: 0,
    },
    // Ставка налога
    taxRate: 13.35,
    taxSum: {
      // 2.1. Сумма налога
      plan: 0,
      fact: 0,
    },
    // Ставка комиссии банка за снятие наличности
    cashingBankComission: 0.29,
    cashingComissionSum: {
      // 2.2. Сумма комиссии
      plan: 0,
      fact: 0,
    },
    // Ставка средних накладных налогов
    overheadRate: 3.34,
    overheadAmountSum: {
      // 2.3. Сумма накладных расходов на проект
      plan: 0,
      fact: 0,
    },
    // Ставка расходов на ЗП сотрудников
    payrollCostRate: 2.27,
    payrollAmountSum: {
      // 2.4. ЗП сотрудников на проект
      plan: 0,
      fact: 0,
    },
    hiContractSum: {
      // Hi Сумма Контракта без НДС, за вычетом безвозвратного обеспечения и сумм, выплаченных организациям-соисполнителям, за вычетом постоянных издержек
      plan: 0,
      fact: 0,
    },
    bonusScheme: {
      // 3.1. Схема бонусов
      plan: 0,
      fact: 0,
    },
    externalExpertsPayout: {
      // 3.2. Оплата внешних экспертов
      plan: 0,
      fact: 0,
    },
    otherProjectExpences: {
      // 3.3. Другие проектные расходы
      plan: 0,
      fact: 0,
    },
    reserve: {
      // 3.4. Резерв
      plan: 0,
      fact: 0,
    },
    priProjectProfit: {
      // Pri - Прибыль проекта (Сумма контрактов без НДС, безвозвр.обеспеч.,сумм организаций-соисполнителей, за вычетом постоянных издержек, за вычетом переменных издержек)
      plan: 0,
      fact: 0,
    },
    profitRate: {
      // Profit Доля проекта в прибыли (Отношение прибыли проекта к прибыли команды)
      plan: 0,
      fact: 0,
    },
  };
};
export const finPassport = (item, projectFinance) => {
  // TODO вынести математику в отдельную функцию
  //   Проверка существования свойств
  if (!projectFinance.cashlessPayment) {
    projectFinance.cashlessPayment = { plan: 0, fact: 0 };
  }
  if (!projectFinance.income) {
    projectFinance.income = { plan: 0, fact: 0 };
  }
  if (!projectFinance.externalExpertsPayout) {
    projectFinance.externalExpertsPayout = { plan: 0, fact: 0 };
  }
  if (!projectFinance.otherProjectExpences) {
    projectFinance.otherProjectExpences = { plan: 0, fact: 0 };
  }
  if (!projectFinance.irretrievablуGuarantee) {
    projectFinance.irretrievablуGuarantee = { plan: 0, fact: 0 };
  }
  if (!projectFinance.nds) {
    projectFinance.nds = { plan: 0, fact: 0 };
  }
  if (!projectFinance.bonusScheme) {
    projectFinance.bonusScheme = { plan: 0, fact: 0 };
  }
  if (!projectFinance.contractSum) {
    projectFinance.contractSum = { plan: 0, fact: 0 };
  }
  if (!projectFinance.cashlessReserve) {
    projectFinance.cashlessReserve = { plan: 0, fact: 0 };
  }
  if (!projectFinance.reserve) {
    projectFinance.reserve = { plan: 0, fact: 0 };
  }
  //   заполнение суммами из проводок
  if (item.data.article && item.data.article.id == 'ex91') {
    // console.log('Сторонние соисполнители', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.cashlessPayment.plan += +item.data.sum;
    } else {
      projectFinance.cashlessPayment.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 'ex92') {
    // console.log('Оплата внешних экспертов', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.externalExpertsPayout.plan += +item.data.sum;
    } else {
      projectFinance.externalExpertsPayout.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 'ex93') {
    // console.log('Другие проектные расходы', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.otherProjectExpences.plan += +item.data.sum;
    } else {
      projectFinance.otherProjectExpences.fact += +item.data.sum;
    }
  }
  if (
    item.data.article &&
    (item.data.article.id == 'ex94' ||
      item.data.article.id == 'ex161' ||
      item.data.article.id == 'ex171' ||
      item.data.article.id == 'ex181')
  ) {
    // console.log('Безвозвратное обеспечение', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.irretrievablуGuarantee.plan += +item.data.sum;
    } else {
      projectFinance.irretrievablуGuarantee.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 'ex95') {
    // console.log(
    //   'НДС согласно контракту за вычетом НДС уплачиваемого соисполнителем',
    //   item.data.sum
    // );
    if (item.data.wiringType == 1) {
      projectFinance.nds.plan += +item.data.sum;
    } else {
      projectFinance.nds.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 'ex191') {
    // console.log('Безвозвратное обеспечение ', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.bonusScheme.plan += +item.data.sum;
    } else {
      projectFinance.bonusScheme.fact += +item.data.sum;
    }
  }
  if (
    item.data.article &&
    (item.data.article.id == 'in11' ||
      item.data.article.id == 'in12' ||
      item.data.article.id == 'in13' ||
      item.data.article.id == 'in14' ||
      item.data.article.id == 'in15')
  ) {
    // console.log('Сумма контракта', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.contractSum.plan += +item.data.sum;
    } else {
      projectFinance.contractSum.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 're31') {
    // console.log('Резерв', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.cashlessReserve.plan += +item.data.sum;
    } else {
      projectFinance.cashlessReserve.fact += +item.data.sum;
    }
  }
  if (item.data.article && item.data.article.id == 're41') {
    // console.log('Резерв', item.data.sum);
    if (item.data.wiringType == 1) {
      projectFinance.reserve.plan += +item.data.sum;
    } else {
      projectFinance.reserve.fact += +item.data.sum;
    }
  }
  return projectFinance;
};
export const calculatePassport = (projectFinance) => {
  // TODO Сделать расчет параметров паспорта
  if (!projectFinance.viContractSum) {
    projectFinance.viContractSum = { plan: 0, fact: 0 };
  }
  // TODO hardcode - брать из справочника
  if (!projectFinance.taxRate) {
    projectFinance.taxRate = 13.35;
  }
  if (!projectFinance.taxSum) {
    projectFinance.taxSum = { plan: 0, fact: 0 };
  }
  // TODO hardcode - брать из справочника
  if (!projectFinance.cashingBankComission) {
    projectFinance.cashingBankComission = 0.29;
  }
  if (!projectFinance.cashingComissionSum) {
    projectFinance.cashingComissionSum = { plan: 0, fact: 0 };
  }
  // TODO hardcode - брать из справочника
  if (!projectFinance.overheadRate) {
    projectFinance.overheadRate = 3.34;
  }
  if (!projectFinance.overheadAmountSum) {
    projectFinance.overheadAmountSum = { plan: 0, fact: 0 };
  }
  // TODO hardcode - брать из справочника
  if (!projectFinance.payrollCostRate) {
    projectFinance.payrollCostRate = 2.27;
  }
  if (!projectFinance.payrollAmountSum) {
    projectFinance.payrollAmountSum = { plan: 0, fact: 0 };
  }
  if (!projectFinance.hiContractSum) {
    projectFinance.hiContractSum = { plan: 0, fact: 0 };
  }
  if (!projectFinance.bonusScheme) {
    projectFinance.bonusScheme = { plan: 0, fact: 0 };
  }
  if (!projectFinance.externalExpertsPayout) {
    projectFinance.externalExpertsPayout = { plan: 0, fact: 0 };
  }
  if (!projectFinance.otherProjectExpences) {
    projectFinance.otherProjectExpences = { plan: 0, fact: 0 };
  }
  if (!projectFinance.reserve) {
    projectFinance.reserve = { plan: 0, fact: 0 };
  }
  if (!projectFinance.priProjectProfit) {
    projectFinance.priProjectProfit = { plan: 0, fact: 0 };
  }
  //
  //
  // Vi Сумма Контракта без НДС, за вычетом безвозвратного обеспечения и сумм, выплаченных организациям-соисполнителям
  projectFinance.viContractSum.plan =
    projectFinance.contractSum.plan -
    projectFinance.nds.plan -
    projectFinance.cashlessPayment.plan -
    projectFinance.irretrievablуGuarantee.plan;
  projectFinance.viContractSum.fact =
    projectFinance.contractSum.fact -
    projectFinance.nds.fact -
    projectFinance.cashlessPayment.fact -
    projectFinance.irretrievablуGuarantee.fact;

  // 2.1 сумма налога: сумма контракта - ндс / 100 * ставку налога
  projectFinance.taxSum.plan =
    ((projectFinance.contractSum.plan - projectFinance.nds.plan) *
      projectFinance.taxRate) /
    100;
  projectFinance.taxSum.fact =
    ((projectFinance.contractSum.fact - projectFinance.nds.fact) *
      projectFinance.taxRate) /
    100;
  // 2.2 Сумма комиссии:
  projectFinance.cashingComissionSum.plan =
    ((projectFinance.contractSum.plan - projectFinance.nds.plan) *
      projectFinance.cashingBankComission) /
    100;
  projectFinance.cashingComissionSum.fact =
    ((projectFinance.contractSum.fact - projectFinance.nds.fact) *
      projectFinance.cashingBankComission) /
    100;
  // 2.3 Сумма накладных налогов:
  projectFinance.overheadAmountSum.plan =
    ((projectFinance.contractSum.plan - projectFinance.nds.plan) *
      projectFinance.overheadRate) /
    100;
  projectFinance.overheadAmountSum.fact =
    ((projectFinance.contractSum.fact - projectFinance.nds.fact) *
      projectFinance.overheadRate) /
    100;
  // 2.4 ЗП сотрудников на проект:
  projectFinance.payrollAmountSum.plan =
    ((projectFinance.contractSum.plan - projectFinance.nds.plan) *
      projectFinance.payrollCostRate) /
    100;
  projectFinance.payrollAmountSum.fact =
    ((projectFinance.contractSum.fact - projectFinance.nds.fact) *
      projectFinance.payrollCostRate) /
    100;
  //
  //
  // Расчет Hi Сумма Контракта без НДС, за вычетом безвозвратного обеспечения и сумм, выплаченных организациям-соисполнителям, за вычетом постоянных издержек
  projectFinance.hiContractSum.plan =
    projectFinance.viContractSum.plan -
    projectFinance.taxSum.plan -
    projectFinance.cashingComissionSum.plan -
    projectFinance.overheadAmountSum.plan -
    projectFinance.payrollAmountSum.plan;
  projectFinance.hiContractSum.fact =
    projectFinance.viContractSum.fact -
    projectFinance.taxSum.fact -
    projectFinance.cashingComissionSum.fact -
    projectFinance.overheadAmountSum.fact -
    projectFinance.payrollAmountSum.fact;

  //
  //
  // Pri - Прибыль проекта (Сумма контрактов без НДС, безвозвр.обеспеч.,сумм организаций-соисполнителей, за вычетом постоянных издержек, за вычетом переменных издержек)
  projectFinance.priProjectProfit.plan =
    projectFinance.hiContractSum.plan -
    projectFinance.bonusScheme.plan -
    projectFinance.externalExpertsPayout.plan -
    projectFinance.otherProjectExpences.plan -
    projectFinance.reserve.plan;
  projectFinance.priProjectProfit.fact =
    projectFinance.hiContractSum.fact -
    projectFinance.bonusScheme.fact -
    projectFinance.externalExpertsPayout.fact -
    projectFinance.otherProjectExpences.fact -
    projectFinance.reserve.fact;
  //
  //
  //
  return projectFinance;
};
const dataIsNotProvided = (item) => {
  return !item || item == ' ';
};
export const editFormValidator = (data) => {
  if (
    dataIsNotProvided(data.funds) ||
    dataIsNotProvided(data.budgetYear) ||
    dataIsNotProvided(data.wiringType) ||
    dataIsNotProvided(data.wiringTypeDir) ||
    dataIsNotProvided(data.funds) ||
    dataIsNotProvided(data.article) ||
    dataIsNotProvided(data.paymentDate) ||
    dataIsNotProvided(data.paymentMethod) ||
    dataIsNotProvided(data.contractor) ||
    dataIsNotProvided(data.sum)
  ) {
    return false;
  } else {
    return true;
  }
};
export const projectsSumsReset = (oldProjectsSums) => {
  oldProjectsSums.contractSums = { plan: 0, fact: 0 };
  oldProjectsSums.viContractSums = { plan: 0, fact: 0 };
  oldProjectsSums.hiContractSumss = { plan: 0, fact: 0 };
  oldProjectsSums.priProjectProfitSums = { plan: 0, fact: 0 };
  return oldProjectsSums;
  //   return {
  //     contractSums: { plan: 0, fact: 0 },
  //     viContractSums: { plan: 0, fact: 0 },
  //     hiContractSums: { plan: 0, fact: 0 },
  //     priProjectProfitSums: { plan: 0, fact: 0 },
  //   };
};
