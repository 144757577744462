<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <v-container v-if="apiLoaded">

        <h1>
          <span>Заявки</span>
        </h1>

        <Register :config="regConfig" ref="registerComponent"></Register>


      </v-container>
    </transition>
  </div>
</template>

<script>

import {applicationsRegisterConfig} from '../register/RegisterConfigs';
import Register from '../register/Register.vue';
import {
  generateFormalEmployeesRegisterConfig,
  generateOfferEmployeesRegisterConfig
} from "@/modules/SharedFunctions";

export default {
  name: 'ApplicationsComponent',
  props: ['setSection'],
  components: {Register},
  data() {
    return {
      apiLoaded: false,
      offerExperts: [],
      formalExpers: [],
      regConfig: {
        tabs: [
          {
            title: 'Заявки',
            config: applicationsRegisterConfig
          }
        ]
      }
    }
  },
  methods: {},
  async beforeMount() {
    this.setSection('APPLICATIONS')
    const formalEmployeesRegisterConfig = await generateFormalEmployeesRegisterConfig()
    const offerEmployeesRegisterConfig = await generateOfferEmployeesRegisterConfig()
    console.log(this.regConfig.tabs[0].config.filters[4])
    this.regConfig.tabs[0].config.filters
        .find(e => {
          return e.path === 'offerExpert.id'
        })
        .regConfig = offerEmployeesRegisterConfig
    this.regConfig.tabs[0].config.filters
        .find(e => {
          return e.path === 'formalExpert.id'
        })
        .regConfig = formalEmployeesRegisterConfig
    this.apiLoaded = true
  },
  async beforeRouteUpdate(to, from, next) {
    console.log('to')
    await this.$refs.registerComponent.beforeRouteUpdatedEvent(to, from, next)
    next()
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

