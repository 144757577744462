<template>
  <div>
    <div
      class="search-wrap"
      style="position: absolute; right: 10px; margin-top: -87px"
    >
      <div class="sort">
        <v-menu
          v-if="config.sortFields != null && config.sortFields.length > 0"
          style="max-width: 300px"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <a v-on="on">{{
              getSortField() != null ? getSortField().title : 'выберите поле'
            }}</a>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-list-item-group mandatory color="primary">
                <v-list-item
                  v-for="(field, fieldIndex) in config.sortFields"
                  :key="fieldIndex + 'sortitems'"
                >
                  <v-list-item-content
                    @click="
                      sortField = field.path;
                      reload();
                    "
                  >
                    <v-list-item-title
                      >Сортировать по {{ field.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn
          x-small
          outlined
          @click="
            sortAsc = !sortAsc;
            reload();
          "
          v-bind:class="{ sortDesk: sortAsc }"
          v-if="config.sortFields != null && config.sortFields.length > 0"
        >
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.16667 -1.42833e-07L4.16667 11.8083L1.175 8.825L-2.7869e-07 10L5 15L10 10L8.825 8.825L5.83333 11.8083L5.83333 -1.99966e-07L4.16667 -1.42833e-07Z"
              fill="#6759D6"
            />
          </svg>
        </v-btn>
        <v-btn @click="filtersOpened = !filtersOpened"
          >Поиск
          <v-icon v-if="!filtersOpened">mdi-magnify</v-icon>
          <v-icon v-if="filtersOpened">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-btn
        outlined
        rounded
        class="download"
        @click="exportRegisterData()"
      ></v-btn>
    </div>
    <Transition name="fade" mode="out-in">
      <form
        class="list-filters"
        v-if="filtersOpened"
        @submit.prevent="reload()"
      >
        <div>
          <template v-for="(filter, filterIndex) in config.filters">
            <v-text-field
              v-if="filter.type === 'STRING'"
              clearable
              filled
              dense
              :label="filter.title"
              v-model="filters[filter.path]"
              :key="filterIndex + 'filterindex'"
            ></v-text-field>
            <v-autocomplete
              v-if="filter.type === 'DICT'"
              clearable
              filled
              dense
              :label="filter.title"
              :key="filterIndex + 'filterindex'"
              v-model="filters[filter.path]"
              :items="filter.dict"
              item-value="code"
              item-text="title"
            ></v-autocomplete>
            <template v-if="filter.type === 'DATE-RANGE'">
              <div :key="filterIndex" class="date-range">
                <label>{{ filter.title }}:</label>
                <date-field
                  label="с"
                  :key="filterIndex + 'filterindexr1'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_more'] = $event; $forceUpdate()"
                  :value="filters[filter.path + '_more']"
                >
                </date-field>
                <date-field
                  label="по"
                  :key="filterIndex + 'filterindexr2'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_less'] = $event; $forceUpdate()"
                  :value="filters[filter.path + '_less']"
                >
                </date-field>
              </div>
            </template>
            <template v-if="filter.type === 'NUMBER-RANGE'">
              <div :key="filterIndex" class="number-range">
                <label>{{ filter.title }}:</label>
                <v-text-field
                  v-mask="thousandsSeparatorMasks[filterIndex]"
                  label="от"
                  :key="filterIndex + 'filterindexr1'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_more']"
                ></v-text-field>
                <v-text-field
                  label="до"
                  v-mask="thousandsSeparatorMasks[filterIndex]"
                  :key="filterIndex + 'filterindexr2'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_less']"
                ></v-text-field>
              </div>
            </template>
            <template v-if="filter.type === 'REGISTER'">
              <register-select-field
                :key="filterIndex + 'filterindex'"
                :field-title="filter.title"
                :dialog-title="filter.dialogTitle"
                :reg-config="filter.regConfig"
                page-size="10"
                default-tab="0"
                :text-function="
                  (item) => (item != null ? item[filter.itemText] : '')
                "
                :text-search-attribute="filter.itemText"
                :value-object="registerValueObjects[filter.path]"
                @select="
                  filters[filter.path] = $event[filter.itemValue];
                  registerValueObjects[filter.path] = $event;
                  $forceUpdate();
                "
                @clear="
                  delete filters[filter.name];
                  $forceUpdate();
                "
              >
              </register-select-field>
            </template>

            <template v-if="filter.type === 'RADIO'">
              <v-radio-group
                v-model="filters[filter.path]"
                :key="filterIndex + 'filterindex'"
                row
              >
                <v-radio
                  v-for="path in filter.path"
                  :key="path"
                  :value="path"
                  :label="path"
                ></v-radio>
              </v-radio-group>

            </template>

          </template>
        </div>

        <div>
          <v-btn
            outlined
            @click="loginput('clear all');
              filters = {};
              registerValueObjects = {};
              reload();
              $forceUpdate()
            "
          >
            Сбросить все
          </v-btn>
          <v-btn @click="reload()"> Применить </v-btn>
          <input type="submit" hidden />
        </div>
      </form>
    </Transition>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <section v-if="firstPageLoaded">
        <div v-if="entries.length === 0">
          Не найдены результаты, удовлетворяющие запросу
        </div>
        <ul class="register-list">
          <li
            v-for="(entry, entryIndex) in entries"
            :key="entryIndex + 'counterpart'"
          >
            <component
              :is="config.entryComponent"
              :entry="entry"
              @select="$emit('select', $event)"
              :select-mode="selectMode"
            >
            </component>
          </li>
        </ul>

        <v-btn
          v-if="!loadingInProgress && hasMorePages"
          outlined
          class="showMore"
          @click="loadNextPage()"
        >
          Загрузить еще
        </v-btn>
        <DownloadProgressDialog
          :show="showDownloadProgressDialog"
        ></DownloadProgressDialog>
      </section>
    </transition>
  </div>
</template>

<script>
import api from '@/modules/api';
import DateField from '../elements/DateField.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import RegisterSelectField from './RegisterSelectField.vue';
import Notification44Entry from './Notification44Entry.vue';
import NotificationEntry from './NotificationEntry.vue';
import Notification223Entry from './Notification223Entry.vue';
import EmployeeEntry from './EmployeeEntry.vue';
import ApplicationEntry from './ApplicationEntry.vue';
import ContractEntry from './ContractEntry.vue';
import ProjectEntry from './ProjectEntry.vue';
import CounterpartyEntry from './CounterpartyEntry.vue';
import WRegisterEntry from './WRegisterEntry.vue';
import DownloadProgressDialog from "@/components/register/DownloadProgressDialog";
import {downloadFile, getQueryObject} from "@/modules/CommonUtils";

export default {
  name: 'RegisterTab',
  //конфиг - джсон с конфигурацией табы реестра, pickMode - запускаем ли в режиме выбора записи, presets - предустановленные фильтры
  props: ['config', 'selectMode', 'presets'],
  components: {
    DownloadProgressDialog,
    DateField,
    RegisterSelectField,
    NotificationEntry,
    Notification44Entry,
    Notification223Entry,
    EmployeeEntry,
    ApplicationEntry,
    ContractEntry,
    ProjectEntry,
    CounterpartyEntry,
    WRegisterEntry,
  },
  data() {
    return {
      loadingInProgress: true,
      filtersOpened: false,
      sortAsc: true,
      sortField: '',
      filters: {},
      registerValueObjects: {}, //хранилище для объектов-значений фильтров типа REGISTER
      page: -1, //при первой загрузке будет сразу увеличено до 0
      hasMorePages: false, // отображать ли кнопку "Загрузить еще"
      entries: [], //загруженные записи реестра
      firstPageLoaded: false,
      showDownloadProgressDialog: false,
      thousandsSeparatorMasks: []
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return '';
      }
      let mdate = new Date(date);
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return mdate.toLocaleDateString('ru-RU', options);
    },
  },
  methods: {
    loginput(msg){
      console.log(msg)
    },
    //"перезагрузить" реестр: заново с первой страницы (но фильтры и сортировки остаются)
    async reload() {
      const urlParams = this.formQuerySpec();
      urlParams._tab = getQueryObject()._tab;
      await this.$router.push({
        path: window.location.pathname,
        query: urlParams,
      });
    },

    async reloadRows() {
      this.page = -1;
      this.entries = [];
      await this.loadNextPage();
    },

    //возвращает параметр для запроса get библиотеки api
    formQuerySpec() {
      let query = {};
      query.page = this.page;
      query.size = this.config.pageSize;
      if (this.sortField != null)
        query.sort = this.sortField + (this.sortAsc ? ',asc' : ',desc');

      query.search = '';
      this.removeEmptyFilters();
      for (let filter of this.config.filters) {
        if (
          (filter.type === 'STRING' ||
            filter.type === 'DICT' ||
            filter.type === 'REGISTER') &&
          this.isFilterSet(filter.path)
        ) {
          query.search +=
            filter.path + ':' + this.getFilterValue(filter.path) + ',';
        } else if (
          filter.type === 'DATE-RANGE' ||
          filter.type === 'NUMBER-RANGE'
        ) {
          let sincePath = filter.path + '_more';
          let beforePath = filter.path + '_less';
          if (this.isFilterSet(sincePath))
            query.search +=
              filter.path + '>' + this.getFilterValue(sincePath) + ',';
          if (this.isFilterSet(beforePath))
            query.search +=
              filter.path + '<' + this.getFilterValue(beforePath) + ',';
        }
      }

      if (
        this.config.apiConfigPresets &&
        this.config.apiConfigPresets.length > 0
      ) {
        if (query.search.length > 0) query.search += ',';
        query.search += this.config.apiConfigPresets;
      }

      if (query.search.length === 0) delete query.search;
      else if (query.search.charAt(query.search.length - 1) === ',')
        query.search = query.search.substring(0, query.search.length - 1); //убрать последний символ - это ','
      return query;
    },

    //задан ли фильтр с path
    isFilterSet(path) {
      return (
        this.getFilterValue(path) != null &&
        this.getFilterValue(path).toString().length > 0
      );
    },

    //получить значение фильтра с path
    getFilterValue(path) {
      return this.filters[path];
    },

    //убирает из фильтров пустые поля
    //ввели фильтр - стерли фильтр. Получился пустой фильтр. Его нужно убрать
    removeEmptyFilters() {
      for (let attr in this.filters) {
        if (
          this.filters[attr] == null ||
          this.filters[attr].length === 0 ||
          this.filters[attr] === false
        )
          delete this.filters[attr];
      }
    },

    //загрузить следующую страницу реестра
    async loadNextPage() {
      this.loadingInProgress = true;
      this.page++;
      let portion = (
        await api.get(this.config.apiRestEndpoint, this.formQuerySpec())
      ).payload;

      if (this.config.customFilterFunction != null)
        portion = this.filterWithCustomFilterFunction(portion);
      this.entries = this.entries.concat(portion);

      this.hasMorePages = portion.length === this.config.pageSize;
      this.loadingInProgress = false;
    },

    //фильтрует массив записей с помощью this.config.customFilterFunction
    //customFilterFunction возвращает либо null (тогда запись не включается), либо запись, которую нужно включить
    filterWithCustomFilterFunction(entries) {
      let res = [];
      for (let e of entries) {
        let r = this.config.customFilterFunction(e);
        if (r) {
          res.push(r);
        }
      }
      return res;
    },

    //возвращает текущее поле для сортировки, как объект. нужно для отображения в интерфейсе поля, по которому идет сортировка
    getSortField() {
      for (let field of this.config.sortFields) {
        if (field.path === this.sortField) {
          return field;
        }
      }
    },

    async exportRegisterData() {
      this.showDownloadProgressDialog = true
      const waitPromise = new Promise(resolve => setTimeout(resolve, 1000))
      await downloadFile(this.config.apiRestEndpoint + '/export/', this.formQuerySpec())
      await waitPromise
      this.showDownloadProgressDialog = false
    },

    setSortParamsFromUrl(urlParams) {
      if (urlParams.sort) {
        let [sortField, sortDirection] = urlParams.sort.split(',');
        this.sortField = sortField;
        this.sortAsc = sortDirection === 'asc';
      } else {
        this.sortField = this.config.defaultSortField;
        this.sortAsc = !!this.config.defaultSortAsc
      }
    },

    setSearchParamsFromUrl(urlParams) {
      this.filters = {};
      let search = urlParams.search;
      if (!search) return;
      search += ',';
      const regex = new RegExp('([\\w\\.а-я]+)([:<>$])([\\wа-я| -]+),', 'g');
      for (let m of search.matchAll(regex)) {
        if (m[2] === ':') {
          this.filters[m[1]] = m[3];
        } else if (m[2] === '>') {
          this.filters[m[1] + '_more'] = m[3];
        } else if (m[2] === '<') {
          this.filters[m[1] + '_less'] = m[3];
        }
      }
    },

    setParamsFromUrl(params) {
      const urlParams = params ?? getQueryObject();
      this.setSortParamsFromUrl(urlParams);
      this.setSearchParamsFromUrl(urlParams);
    },

    async beforeRouteUpdatedEvent(to) {
      this.setParamsFromUrl(to.query);
      await this.reloadRows();
    },
  },
  async beforeMount() {
    let i = 0
    for(let filter of this.config.filters){
      if(filter.type === 'NUMBER-RANGE'){
        this.thousandsSeparatorMasks[i] = createNumberMask({
          prefix: '',
          allowDecimal: true,
          includeThousandsSeparator: true,
          allowNegative: false,
        })
      }
      i++
    }
    console.log(this.thousandsSeparatorMasks)


    this.setParamsFromUrl();
    await this.loadNextPage();
    this.firstPageLoaded = true;
  },
};
</script>

<style>
.v-input--radio-group--row .v-input__slot {
  background: none !important;
}
</style>

